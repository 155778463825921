import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import 'primereact/resources/primereact.min.css';           // Core CSS
import 'primeicons/primeicons.css';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const getRandomQuote = async () => {
  const url = 'https://trumponce.com/api/entry';
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data.document;
};

const fetchQuoteById = async (uuid) => {
  const url = `https://trumponce.com/api/entry?id=${uuid}`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data.document;
};

const App = () => {
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasNavigated, setHasNavigated] = useState(false); // Track if we've navigated to an ID already
  const query = useQuery();
  const navigate = useNavigate();

  // Function to fetch and set a random quote
  const fetchRandomQuote = async () => {
    try {
      setLoading(true);  // Start loading
      const fetchedQuote = await getRandomQuote();
      setQuote(fetchedQuote);
      setError(null);  // Clear any previous errors
      // Navigate to the new quote's URL if we haven't already navigated
      if (fetchedQuote && fetchedQuote.uuid) {
        navigate(`/entry?id=${fetchedQuote.uuid}`);
        setHasNavigated(true);
      }
    } catch (err) {
      setError('Error fetching the quote: ' + err.message);
    } finally {
      setLoading(false);  // Stop loading after fetching
    }
  };

  // Initial load based on URL query
  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const uuid = query.get('id');
        let fetchedQuote;

        if (uuid) {
          // Fetch a specific quote by UUID
          fetchedQuote = await fetchQuoteById(uuid);
        } else {
          // Fetch a random quote
          fetchedQuote = await getRandomQuote();
        }

        setQuote(fetchedQuote);
        setError(null);

        // Navigate to the URL with the quote's UUID once it's fetched, but only if there's no UUID in the query
        if (!uuid && fetchedQuote && fetchedQuote.uuid) {
          navigate(`/entry?id=${fetchedQuote.uuid}`);
          // setHasNavigated(true); // Prevent further navigation after the initial one
        }
      } catch (err) {
        console.error('Error fetching the quote:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuote();
  }, []);

  // Handle page click to fetch a new quote
  const handlePageClick = () => {
    fetchRandomQuote();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container" onClick={handlePageClick}>
      <a href="https://secure.actblue.com/donate/notgoingbackforharris">
        <img src="harris.png" alt="Donate to Harris" style={{ width: '50%', display: 'block', margin: 'auto', padding: '20px' }} />
      </a>
      {quote && (
        <Card title="Did you know Trump once...">
          <p dangerouslySetInnerHTML={{ __html: quote.quote }}></p>
          {quote.links && quote.links.length > 0 && (
            <ul>
              {quote.links.map((link, index) => (
                <li key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer">Source</a>
                </li>
              ))}
            </ul>
          )}
        </Card>
      )}
      <p style={{ fontSize: '12pt' }}>Click/Tap to get another.</p>
    </div>
  );
};

export default App;
