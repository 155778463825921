import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const SubmitEntry = () => {
    const [quote, setQuote] = useState('');
    const [uuid, setUuid] = useState('');
    const [links, setLinks] = useState(['']);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const generatedUuid = uuidv4();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            quote,
            uuid: generatedUuid,
            links
        };

        try {
            const response = await axios.post('https://trumponce.com/api/submit-entry', payload);
            setSuccess('Document submitted successfully');
            setError('');

            // Clear the form fields
            setQuote('');
            setLinks(['']);

            // Fade out the success message after 2 seconds
            setTimeout(() => {
                setSuccess('');
            }, 2000);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.detail) {
                setError(`Error: ${err.response.data.detail.map(d => d.msg).join(', ')}`);
            } else {
                setError('An error occurred');
            }
            setSuccess('');

            // Fade out the error message after 2 seconds
            setTimeout(() => {
                setError('');
            }, 2000);
        }
    };

    const handleLinkChange = (index, value) => {
        const newLinks = [...links];
        newLinks[index] = value;
        setLinks(newLinks);
    };

    const addLinkField = () => {
        setLinks([...links, '']);
    };

    return (
        <div>
            <h1>Submit Entry</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Quote:</label>
                    <textarea
                        type="text"
                        value={quote}
                        cols={50}
                        rows={5}
                        onChange={(e) => setQuote(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Links:</label>
                    {links.map((link, index) => (
                        <div key={index}>
                            <input
                                type="url"
                                value={link}
                                onChange={(e) => handleLinkChange(index, e.target.value)}
                                required
                            />
                        </div>
                    ))}
                    <button type="button" onClick={addLinkField}>Add another link</button>
                </div>
                <button type="submit">Submit</button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}
        </div>
    );
};

export default SubmitEntry;
